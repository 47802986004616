@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-category {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--elevation-1);
  outline: 2px solid var(--cst-cards-category-outline-default);
  outline-offset: -1px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  border-radius: var(--cornerradius12);
  padding: 14px;
  gap: 6px;

  @include fixedSize(100%, 68px); //292

  @include min-428-break {
    padding: 31px 24px;
    border-radius: var(--cornerradius16);
    @include fixedSize(100%, 114px); //400
  }

  @include min-744-break {
    @include fixedSize(100%, 114px); //338
  }

  @include min-1024-break {
    @include fixedSize(100%, 84px); //240
    padding: 24px 18px;
  }

  @include min-1440-break {
    @include fixedSize(100%, 114px); //440px
    padding: 31px 24px;
    gap: 8px;
  }

  @include min-1920-break {
    @include fixedSize(100%, 114px); //394
    padding: 31px 24px;
  }

  &:hover {
    outline: 2px solid var(--cst-cards-category-outline-hover);
    .item {
      .background {
        scale: 1.15;
      }
    }

    .titles {
      a {
        color: #8f8f8f;
      }
    }
  }
}

.titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 3px;

  .link {
    text-decoration: none;
    display: flex;
    gap: 8px;
    color: rgba(170, 170, 170, 0.5);
    transition: all 0.2s ease;
  }

  @include min-1024-break {
    gap: 4px;
  }

  .title {
    color: var(--cst-cards-category-title-default);

    @include typography-base;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-xl;
      @include weight-semi-bold;
    }

    @include min-1024-break {
      @include typography-s;
      @include weight-semi-bold;
    }

    @include min-1440-break {
      @include typography-xl;
      @include weight-semi-bold;
    }
  }

  .subtitle {
    color: var(--cst-cards-category-subtitle-default);
    @include typography-xs;
    @include weight-medium;

    @include min-428-break {
      @include typography-s;
      @include weight-medium;
    }

    @include min-1024-break {
      @include typography-xs;
      @include weight-medium;
    }

    @include min-1440-break {
      @include typography-s;
      @include weight-medium;
    }
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -3px;
  z-index: 1;
  pointer-events: none;

  @include min-428-break {
    right: 3px;
  }

  .item {
    z-index: 2;

    @include singleFixedSize(52px);

    @include min-428-break {
      @include singleFixedSize(96px);
    }

    @include min-1024-break {
      @include singleFixedSize(68px);
    }

    @include min-1440-break {
      @include singleFixedSize(96px);
    }
  }
}

.background {
  position: absolute;
  z-index: 0;
  @include singleFixedSize(130px);

  @include min-428-break {
    @include singleFixedSize(178px);
  }

  @include min-1024-break {
    @include singleFixedSize(130px);
  }

  @include min-1440-break {
    @include singleFixedSize(178px);
  }
}

.icon {
  @include singleFixedSize(13px);

  @include min-428-break {
    @include singleFixedSize(18px);
  }

  @include min-1024-break {
    @include singleFixedSize(13px);
  }

  @include min-1440-break {
    @include singleFixedSize(18px);
  }
}
